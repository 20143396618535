<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mx-auto" elevation="8">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                このページの使い方
              </v-list-item-title>

              <v-list-item-subtitle>
                日本外国語専門学校2020年後期のCS調査回答履歴を確認できます。
                <v-btn x-small rounded @click="toggleShow">
                  <v-icon>{{
                    show ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                  詳細説明を{{ show ? "閉じる" : "開く" }}
                </v-btn>
              </v-list-item-subtitle>

              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>

                  <v-card-text>
                    <ol class="info-messages">
                      <li>
                        自分の履修している授業数と回答件数確認してください。
                      </li>
                      <li>
                        回答した履歴は確認できますが、変更はできません。
                      </li>
                    </ol>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Information",
  data: () => ({}),
  computed: {
    show() {
      return this.$store.state.detailShow;
    }
  },
  methods: {
    toggleShow() {
      this.$store.commit("TOGGLE_DETAIL_SHOW");
    }
  }
};
</script>
<style scoped>
.info-messages li {
  line-height: 1.5rem;
}
.additional-message {
  padding: 1rem 0 0 0;
}
</style>
