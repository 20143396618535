<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <Information />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <SubmittedList />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Information from "../components/Submitted/Information.vue";
import SubmittedList from "../components/Submitted/SubmittedList.vue";

export default {
  name: "Submitted",
  components: {
    Information,
    SubmittedList
  }
};
</script>
