<template>
  <v-container>
    <v-row>
      <v-col>
        回答済み科目数: <span>{{ resultsNumber }} 件</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xm="12"
        sm="6"
        md="4"
        v-for="(c, i) in submitted"
        :key="i"
      >
        <Classroom :classroom="c" view="register" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Classroom from "../Classroom";
export default {
  name: "SubmittedList",

  components: {
    Classroom
  },
  computed: {
    submitted() {
      let results = [];
      const submitted = this.$store.state.submittedList;
      submitted.forEach(s => {
        const result = this.$store.state.classrooms.filter(c => {
          return !!(s.courseId == c.courseCode);
        });
        if (result.length > 0) {
          results.push(result[0]);
        }
      });
      return results;
    },
    resultsNumber() {
      return this.$store.state.submittedList.length;
    }
  },
  methods: {}
};
</script>
